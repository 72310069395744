import React, { useMemo } from "react";

import _ from "lodash";

import moment from "moment";

import Image from "next/image";
import Link from "next/link";

interface BusinessListingProps {
  businessItem: any;
}

const getStoreStatus = (businessSchedules: any[], isOpen: boolean) => {
  if (!businessSchedules || businessSchedules.length === 0) {
    return { isOpen: false, nextOpeningTime: null, nextClosingTime: null };
  }

  console.log("Business schedules:", businessSchedules);

  const now = moment();
  const storeIsOpen = isOpen || false;
  let nextOpeningTime = null;
  let nextClosingTime = null;

  for (let i = 0; i < 7; i++) {
    const checkDay = moment().add(i, "days");
    const dayIndex = checkDay.day();

    const schedules = businessSchedules.filter(
      (el) => parseInt(el.day) === dayIndex
    );

    if (schedules && schedules.length > 0) {
      for (const schedule of schedules) {
        // Extract the time part from the UTC datetime strings
        const openingTimeUtc = moment.utc(schedule.openingTime);
        const closingTimeUtc = moment.utc(schedule.closingTime);

        // Convert to local time
        const openingTimeLocal = openingTimeUtc.clone().local();
        const closingTimeLocal = closingTimeUtc.clone().local();

        const openingTimeFormatted = openingTimeLocal.format("HH:mm:ss");
        const closingTimeFormatted = closingTimeLocal.format("HH:mm:ss");

        // Create moment objects for opening and closing times on the checkDay
        const openingTime = moment(
          `${checkDay.format("YYYY-MM-DD")} ${openingTimeFormatted}`,
          "YYYY-MM-DD HH:mm:ss"
        );
        const closingTime = moment(
          `${checkDay.format("YYYY-MM-DD")} ${closingTimeFormatted}`,
          "YYYY-MM-DD HH:mm:ss"
        );

        // Since closing time is always after opening time, we don't need to adjust for overnight shifts

        if (now.isBetween(openingTime, closingTime)) {
          // We are within this schedule period
          if (!nextClosingTime || closingTime.isBefore(nextClosingTime)) {
            nextClosingTime = closingTime;
          }
        }

        if (now.isBefore(openingTime)) {
          // This opening time is after now
          if (!nextOpeningTime || openingTime.isBefore(nextOpeningTime)) {
            nextOpeningTime = openingTime;
          }
        }
      }
    }
  }

  console.log("Store status is open:", storeIsOpen);
  console.log("Next opening time:", nextOpeningTime);
  console.log("Next closing time:", nextClosingTime);

  return { isOpen: storeIsOpen, nextOpeningTime, nextClosingTime };
};

const BusinessListing: React.FC<BusinessListingProps> = React.memo(
  ({ businessItem }) => {
    // Use useMemo to memoize the src
    const bannerSrc = useMemo(
      () => businessItem?.signedS3UrlBanner || "",
      [businessItem]
    );

    const logoSrc = useMemo(
      () => businessItem?.signedS3UrlLogo || "",
      [businessItem]
    );

    // Add these lines inside your component, before the return statement
    const businessSchedules = businessItem?.businessSchedules;
    const isManualOpenCloseUpdate = businessItem?.isManualOpenCloseUpdate;
    const isOpen = businessItem?.isOpen;

    const {
      isOpen: storeIsOpen,
      nextOpeningTime,
      nextClosingTime,
    } = getStoreStatus(businessSchedules, isOpen);

    return (
      <Link
        href={{
          pathname: "/listing/vendor-item",
          query: {
            type: businessItem?.merchantType,
            id: businessItem?.businessId,
          },
        }}
        key={businessItem?.businessId}
      >
        <div className="flex p-4 flex-col border-t border-t-gray-300">
          <div className="relative">
            <Image
              height={900}
              width={1600}
              src={bannerSrc}
              alt="banner"
              className="object-cover rounded-lg w-full max-h-[165px] max-w-[700px]"
              placeholder="blur"
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 1600px"
              blurDataURL="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIGZpbGw9IiNkZGQiIC8+PC9zdmc+"
            />
            <div className="absolute h-full w-[150px] bottom-0 top-0 left-0 flex items-end p-2">
              <div className="relative h-[75px] w-[75px]">
                <Image
                  src={logoSrc}
                  layout="fill"
                  objectFit="cover"
                  alt="vendor-image"
                  className="rounded-md"
                  sizes="75px"
                  placeholder="blur"
                  blurDataURL="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIGZpbGw9IiNkZGQiIC8+PC9zdmc+"
                />
              </div>
            </div>
          </div>

          <h1 className="text-black font-medium pt-4 text-lg">
            {businessItem?.businessName}
          </h1>

          <p className="text-black font-normal text-xs">
            {businessItem?.formattedAddress}
          </p>

          <div>
            <span
              className={`font-bold ${
                storeIsOpen ? "text-green-500" : "text-red-500"
              } text-sm`}
            >
              {storeIsOpen ? "Open " : "Closed "}
            </span>
            {!isManualOpenCloseUpdate ? (
              <span className="text-sm">
                {storeIsOpen && nextClosingTime
                  ? `until ${nextClosingTime.format("h:mm A")} ${
                      nextClosingTime.isSame(moment(), "day")
                        ? "Today"
                        : nextClosingTime.format("dddd")
                    }`
                  : !storeIsOpen && nextOpeningTime
                    ? `until ${nextOpeningTime.format("h:mm A")} ${
                        nextOpeningTime.isSame(moment(), "day")
                          ? "Today"
                          : nextOpeningTime.format("dddd")
                      }`
                    : ""}
              </span>
            ) : null}
          </div>

          {businessItem?.isAcceptingOrders ? (
            <></>
          ) : (
            <p className="text-xs text-jolt-red font-bold">
              Orders are currently paused for this store.
            </p>
          )}
        </div>
      </Link>
    );
  }
);

export default BusinessListing;
