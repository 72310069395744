import React, { useEffect, useState, useRef } from "react";

import BaseLayout from "@Ship/Layouts";
import DataSvgLoader from "@Ship/Components/Loader/dataSvgLoader";
import _ from "lodash";
import { useSearchLocationLazyQuery } from "../../../../../generated/graphql";
import { useLoadScript } from "@react-google-maps/api";

import BusinessListing from "../../components/BusinessListing";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { AiOutlineAim } from "react-icons/ai";
import { FaSpinner } from "react-icons/fa";

const googleLibraries: GoogleLibrary[] = ["places"];

type GoogleLibrary = "drawing" | "geometry" | "places" | "visualization";

const LandingPage = () => {
  const [filterCoordinates, setFilterCoordinates] = useState<any>({
    latitude: -28.016667,
    longitude: 153.4,
  });

  useLoadScript({
    googleMapsApiKey: process.env.GOOGLE_API_KEY ?? "", // Replace with your actual Google Maps API key
    libraries: googleLibraries,
  });

  /* eslint-disable @typescript-eslint/no-explicit-any */

  const [
    getVendorList,
    { data: vendorListLocationResponse, loading: vendorsLoading },
  ] = useSearchLocationLazyQuery();

  useEffect(() => {
    if (filterCoordinates) {
      getVendorList({
        variables: {
          productsSearchSearchString: "",
          latitude: filterCoordinates?.latitude,
          longitude: filterCoordinates?.longitude,
          page: 1,
          rows: 100,
          maxDistanceInputKm: 100000,
        },
      });
    }
  }, [filterCoordinates]);

  const vendorList =
    vendorListLocationResponse?.patron?.searchLocation?.data ?? [];

  return (
    <>
      <div className="z-9 w-full max-w-[600px] mx-auto max-h-full h-full min-h-full overflow-y-auto flex flex-col bg-white">
        {/* Scrollable content in the middle */}

        <div className="flex-1 overflow-y-auto">
          {/* <div
            className={`justify-items-stretch p-3 bottom-14 absolute w-full z-[4] `}
          >
            <div className="grid grid-cols-2 gap-4">
              <div className="">
                <button
                  className="h-[35px] w-full text-jolt-red bg-jolt-white-red hover:bg-jolt-white-red-focus font-medium rounded-md"
                  onClick={() => setShowMap(false)}
                >
                  Cancel
                </button>
              </div>
              <div className="">
                <button
                  className="h-[35px] w-full text-jolt-white bg-jolt-blue hover:bg-jolt-blue-focus font-medium rounded-md"
                  onClick={() => {
                    getVendorList({
                      variables: {
                        productsSearchBusinessType: "",
                        latitude: filterCoordinates?.latitude,
                        longitude: filterCoordinates?.longitude,
                      },
                    });
                    setShowMap(false);
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div> */}
          <div className="p-4">
            <PlacesAutocomplete setFilterCoordinates={setFilterCoordinates} />
          </div>

          {vendorsLoading ? (
            <DataSvgLoader
              className="h-[calc(100vh-150px)]"
              fillColor="fill-jolt-blue"
            />
          ) : (
            <>
              {vendorList?.map((item: any) => (
                <BusinessListing businessItem={item} />
              ))}

              {vendorList?.length === 0 && (
                <div
                  className={`pt-4 w-full bg-white min-h-[250px] flex justify-center items-center`}
                >
                  <FaSpinner className="animate-spin w-6 h-6 text-5b5-softer-blue" />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

LandingPage.getLayout = (page: any) => {
  return <BaseLayout>{page}</BaseLayout>;
};

LandingPage.whyDidYouRender = true;

export default LandingPage;

const PlacesAutocomplete = ({ setFilterCoordinates }: any) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: "initMap",
  });

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleSelect = async (address: string) => {
    setValue(address, false);
    clearSuggestions();
    const results = await getGeocode({ address });
    const { lat, lng } = results[0]
      ? getLatLng(results[0])
      : { lat: 0, lng: 0 };
    setShowDropdown(false);
    await setFilterCoordinates({ latitude: lat, longitude: lng });
  };

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      setLoading(true);
      setValue("Fetching current location...");
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          const results = await getGeocode({
            location: { lat: latitude, lng: longitude },
          });
          const { lat, lng } = results[0]
            ? getLatLng(results[0])
            : { lat: 0, lng: 0 };
          setLoading(false);
          setValue(results[0] ? results[0].formatted_address : "");
          await setFilterCoordinates({ latitude: lat, longitude: lng });
        },
        (error) => {
          console.error("Error getting the current position: ", error);
          setLoading(false);
          setValue("");
          switch (error.code) {
            case error.PERMISSION_DENIED:
              setValue("Please enable location in your browser");
              break;
            case error.POSITION_UNAVAILABLE:
              setValue("Location information unavailable");
              break;
            case error.TIMEOUT:
              setValue("Location request timed out");
              break;
            default:
              setValue("Error getting location");
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <div className="flex justify-center items-center">
      <div
        ref={dropdownRef}
        className="dropdown dropdown-bottom w-full max-w-[800px] relative bg-transparent flex justify-center items-center"
      >
        <label className="relative flex items-center w-[100%] flex-shrink max-w-[800px]">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`pointer-events-none absolute top-1/2 transform -translate-y-1/2 left-3 w-5 h-5 mobile:w-3.5 mobile:h-3.5`}
          >
            <path
              d="M10.5001 10.5001L8.3286 8.3286M8.3286 8.3286C8.70005 7.95716 8.99469 7.51619 9.19572 7.03088C9.39674 6.54556 9.50021 6.0254 9.50021 5.5001C9.50021 4.9748 9.39674 4.45464 9.19572 3.96933C8.99469 3.48401 8.70005 3.04305 8.3286 2.6716C7.95716 2.30016 7.51619 2.00551 7.03088 1.80449C6.54556 1.60347 6.0254 1.5 5.5001 1.5C4.9748 1.5 4.45464 1.60347 3.96933 1.80449C3.48401 2.00551 3.04305 2.30016 2.6716 2.6716C1.92144 3.42177 1.5 4.43921 1.5 5.5001C1.5 6.561 1.92144 7.57844 2.6716 8.3286C3.42177 9.07877 4.43921 9.50021 5.5001 9.50021C6.561 9.50021 7.57844 9.07877 8.3286 8.3286Z"
              stroke="#686868"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <input
            type="text"
            name="location"
            placeholder="Enter location or use GPS"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              setShowDropdown(true);
            }}
            disabled={!ready}
            className={`w-full text-[20px] disabled:placeholder-black disabled:bg-white disabled:border-gray-200 input text-black bg-white border-2 rounded-md border-gray-200 pl-10 h-[60px] mobile:h-[50px] mobile:pl-8 mobile:text-[14px]`}
          />

          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleGetCurrentLocation();
            }}
            className="ml-2 text-white min-w-[60px] min-h-[60px] mobile:min-w-[50px] mobile:min-h-[50px] rounded-md flex items-center justify-center bg-white border-2 border-gray-200"
          >
            {loading ? (
              <FaSpinner
                className="animate-spin w-6 h-6 mobile:w-5 mobile:h-5"
                color="black"
              />
            ) : (
              <AiOutlineAim
                className="w-6 h-6 mobile:w-5 mobile:h-5"
                color="black"
              />
            )}
          </button>
        </label>
        {showDropdown && (
          <div
            className={`flex justify-start dropdown-content left-0 right-0 z-10 mt-2 origin-top-right border-2 border-gray-200 rounded-md bg-white shadow-lg ring-1 ring-joltpay-blue-bg ring-opacity-5 focus:outline-4`}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex={-1}
          >
            <div className="w-full" role="none">
              {status === "OK" &&
                data.map(({ description }, index) => (
                  <div
                    key={index}
                    className={`flex justify-between py-2 px-2 text-[20px] mobile:text-[14px] bg-transparent text-joltpay-black-text border-b-[1px] border-gray-200`}
                    tabIndex={-1}
                    id="menu-item-0"
                    role="menu-item"
                    onClick={() => {
                      handleSelect(description);
                    }}
                  >
                    {description}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
